$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.editor-border {
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s;
}

.editor__content {
    margin: 5px;
}

.ProseMirror {
    padding: 5px;
}

.ProseMirror-focused {
    outline: none;
}

// editor
.editor {
    position: relative;
    margin: 0;
    padding: 0 5px;
    color: $color-black;

    &__content {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        pre {
            padding: 0.7rem 1rem;
            border-radius: 5px;
            background: $color-black;
            color: $color-white;
            font-size: 0.8rem;
            overflow-x: auto;

            code {
                display: block;
            }
        }

        p code {
            padding: 0.2rem 0.4rem;
            border-radius: 5px;
            font-size: 0.8rem;
            font-weight: bold;
            background: rgba($color-black, 0.1);
            color: rgba($color-black, 0.8);
        }

        ul,
        ol {
            padding-left: 1rem;
        }

        li > p,
        li > ol,
        li > ul {
            margin: 0;
        }

        a {
            color: inherit;
            text-decoration: underline;
        }

        blockquote {
            border-left: 3px solid rgba($color-black, 0.1);
            color: rgba($color-black, 0.8);
            padding-left: 0.8rem;
            font-style: italic;
            background-color: transparent;

            p {
                margin: 0;
            }
        }

        img {
            max-width: 100%;
            border-radius: 3px;
        }

        table {
            border-collapse: collapse;
            table-layout: fixed;
            width: 100%;
            margin: 0;
            overflow: hidden;

            td,
            th {
                min-width: 1em;
                border: 2px solid $color-grey;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;
                > * {
                    margin-bottom: 0;
                }
            }

            th {
                font-weight: bold;
                text-align: left;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: rgba(255, 200, 224, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: 0;
                width: 4px;
                z-index: 20;
                background-color: #adf;
                pointer-events: none;
            }
        }

        .tableWrapper {
            margin: 1em 0;
            overflow-x: auto;
        }

        .resize-cursor {
            cursor: ew-resize;
            cursor: col-resize;
        }
    }

    ul[data-type="todo_list"] {
        padding-left: 0;
    }
    li[data-type="todo_item"] {
        display: flex;
        flex-direction: row;
    }

    .todo-checkbox {
        border: 2px solid $color-black;
        height: 0.9em;
        width: 0.9em;
        box-sizing: border-box;
        margin-right: 10px;
        margin-top: 0.3rem;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;
        border-radius: 0.2em;
        background-color: transparent;
        transition: 0.4s black;
    }
    .todo-content {
        flex: 1;
        > p:last-of-type {
            margin-bottom: 0;
        }
        > ul[data-type="todo_list"] {
            margin: 0.5rem 0;
        }
    }
    li[data-done="true"] {
        > .todo-content {
            > p {
                text-decoration: line-through;
            }
        }
        > .todo-checkbox {
            background-color: $color-black;
        }
    }
    li[data-done="false"] {
        text-decoration: none;
    }
}

// menubar
.menubar-divider {
    border-bottom: 1px solid #ced4da;
    opacity: 0.25;
    margin: 0;
}

.menubar {
    padding: 5px 5px 0 5px;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.is-focused {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
        font-weight: bold;
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $color-black;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
            background-color: rgba($color-black, 0.05);
        }

        &.is-active {
            background-color: rgba($color-black, 0.1);
        }
    }

    span#{&}__button {
        font-size: 13.3333px;
    }
}

.menububble {
    position: absolute;
    display: flex;
    z-index: 20;
    background: $color-black;
    border-radius: 5px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &__button {
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $color-white;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba($color-white, 0.1);
        }

        &.is-active {
            background-color: rgba($color-white, 0.2);
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        font: inherit;
        border: none;
        background: transparent;
        color: $color-white;
    }
}
