// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "~icheck-bootstrap";

@import "~flag-icon-css/sass/flag-icons.scss";

@import "~vue-select/src/scss/vue-select.scss";

@import "./tiptap.scss";

$color-action: #044c9a;

.wn-text-action {
    color: $color-action !important;
}

a.wn-text-action:hover,
a.wn-text-action:focus {
    color: $color-action !important;
}

.active-navigation-link {
    background-color: transparent;
    border-bottom: 3px solid $color-action;
    border-radius: 0px !important;
    font-weight: bold;
    color: $color-action;
}

.border-footer-field {
    border-top: 1px solid #f7f7f7;
}

.border-footer-field:hover {
    background-color: #f7f7f7;
    cursor: pointer;
}

.main-tab-navigation {
    border-bottom: 1px solid rgb(222, 222, 222);
}

/**
custom-title-list
**/
.circle_done {
    color: green;
}

.circle_not_done {
    color: #9d9b9b;
}

.pinned {
    color: red;
}

.sub_task_done {
    text-decoration: line-through;
    color: gray;
}

.sub_task_is_working {
    color: black;
    font-weight: bold;
}

.sub_task_is_working_icon {
    color: #007bff;
}

.not_pinned {
    color: #9d9b9b;
}

.handle {
    color: lightgray;
}
/**
end custom-title-list
**/

.card-wn-custom {
    & .card-header {
        background-color: white;
        border-bottom: 0px;
        padding-bottom: 0px;

        & h5 {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }
    & .card-body {
        padding: 5px;
        margin: 0;
    }
}
